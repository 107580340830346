import React, {Suspense} from 'react'
import {Formiz, FormizStep, useForm} from '@formiz/core';
import {Box, Grid, Stack} from '@chakra-ui/react';
import {PageLayout} from '../layout/PageLayout';

// Step Buttons
import {NextButton} from './buttons/NextButton';
import {PreviousButton} from './buttons/PreviousButton';
import {DotsStepper} from './utils/DotStepper';
import {useIntl} from "react-intl";

// Steps
const UserGoals = React.lazy(() => import('./steps/UserGoals'));
const UserDetails = React.lazy(() => import('./steps/UserDetails'));

const StepperButtons = () => (
    <Stack>
        <Box mt="5">
            <Grid templateColumns="1fr 1fr">
                <Box>
                    <PreviousButton/>
                </Box>
                <Box textAlign="right">
                    <NextButton/>
                </Box>
            </Grid>
        </Box>
    </Stack>
);

export const Stepper = () => {
    const form = useForm({subscribe: 'form'});

    const intl = useIntl();

    const handleSubmit = (values) => {
        let outgoingLink = intl.formatMessage({id: "outgoingUrl"});
        outgoingLink += '?startWeight=' + values.weight + '&targetWeight=' + values.targetWeight;

        if (window.location.search !== '') {
            outgoingLink += '&' + window.location.search.replace('?', '');
        }

        window.location = outgoingLink;
    };

    return (
        <Formiz connect={form} onValidSubmit={handleSubmit}>
            <PageLayout>
                <form noValidate onSubmit={form.submitStep}>
                    <Box className="stepDots-container" p="4" borderRadius="md" mb="6">
                        <DotsStepper/>
                    </Box>
                    <FormizStep name="step1">
                        <Suspense fallback={<></>}>
                            <UserDetails/>
                        </Suspense>
                    </FormizStep>

                    <FormizStep name="step2">
                        <Suspense fallback={<></>}>
                            <UserGoals/>
                        </Suspense>
                    </FormizStep>

                    <Stack spacing="6" mt="8">
                        <StepperButtons title="Dots stepper"/>
                    </Stack>
                </form>
            </PageLayout>
        </Formiz>

    )
}
